<!-- 工时管理 > 工时补录 -->
<template>
  <div v-loading="loading">
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div>
          <el-alert
            title="温馨提示：月度统计补填工时1天的，则不扣除绩效；补填工时2至4天的，若有打卡记录印证，则除第1天不扣除绩效外，其余每天扣除25元绩效；补填工时超过4天的，若有打卡记录印证，则其后每天扣除50元绩效。
"
            type="error"
          >
          </el-alert>
        </div>

        <div class="oh hour_bottom">
          <div class="fl hour_table_div" ref="table">
            <div class="hour_tips">
              <p class="fl">
                <span style="color: #014d67">●</span>
                <span>6小时及以上</span>
              </p>
              <p class="fl">
                <span style="color: #fbb319">●</span>
                <span>不足6小时</span>
              </p>
              <p class="fl">
                <span style="color: #e8523f">●</span>
                <span>不足3小时</span>
              </p>
            </div>
            <el-calendar v-model="dateVal">
              <template slot="dateCell" slot-scope="{ date, data }">
                <div slot="reference" class="div-Calendar" @click="handleCalendar(data)">
                  <div>
                    {{ data.day.split('-').slice(2).join('-') }}
                  </div>
                  <div>
                    <div
                      v-for="(val, index) in workingHoursData"
                      v-if="val.date.indexOf(data.day) != -1"
                      :key="index"
                    >
                      <el-tooltip class="item" effect="dark" placement="top-start">
                        <div v-for="(item, i) in val.data" :key="i" slot="content">
                          <span v-if="item.manhourType == 0"
                            >{{ item.workName }} : {{ item.hour }}
                          </span>
                          <span v-else>
                            {{ item.workName }} :
                            {{ item.hour }}
                          </span>
                        </div>
                        <div class="total_hour" :style="{ backgroundColor: val.bg }">
                          {{ val.total }}
                        </div>
                      </el-tooltip>
                      <div class="zan_tips bgc_6" v-if="panduan(val.data)">暂存</div>
                      <div class="zan_tips bgc_g" v-if="panduan(val.data, 2)">通过</div>
                      <div class="zan_tips bgc_f" v-if="panduan(val.data, 3)">退回</div>
                    </div>
                  </div>
                </div>
              </template>
            </el-calendar>
          </div>
        </div>
      </div>
    </div>
    <!-- 工时补录(弹出框) -->
    <edit-dlg
      v-if="isEditDlg"
      :isEditDlg="isEditDlg"
      :getItemData="itemData"
      :getDate="strDate"
      :fatherMethod="getProjectHourListData"
      :ZHB_projectManager="ZHB_projectManager"
      :ZHB_member="ZHB_member"
      @childEvent="callEventEdit"
    >
    </edit-dlg>

    <!-- 工时统计(组件) -->
    <!-- <project-statistics-dlg v-if="isProjectStatisticsDlg" :getJump="jump"></project-statistics-dlg> -->

    <!-- 参与项目(组件) -->
    <!-- <project-dlg v-if="editShow" :formData="form" :getStr="str"></project-dlg> -->
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDlg: () => import('./EditDlg.vue'),
    // ProjectStatisticsDlg: () => import("../dlg/ProjectStatisticsDlg.vue"),
    // ProjectDlg: () => import("../../project/Edit.vue"),
  },
  data() {
    return {
      loading: false, // 加载
      dateVal: new Date(), // 日期
      workingHoursData: [], // 渲染 日历 数据
      projectData: [], // 参与项目 数据
      jump: 'tbgs', // 跳转 判断 变量(用于 "工时统计 -- 面包屑" 的显示方式)
      itemData: {}, // 获取 "日历" 对应的 项目工时数据
      isEditDlg: false, // 是否 "显示" 或 "隐藏" 工时补录 弹出框
      isProjectStatisticsDlg: false, // 是否 "显示" 或 "隐藏" 工时统计 组件
      strDate: '', // 日期 -- 年-月-日
      editShow: false, // "显示" 或 "隐藏" 项目信息 组件
      form: {}, // 参与项目 表单数据
      str: 'gsgl', // 用于判断 项目详情 标题
      orgObject: [], //组织类型添加
      ZHB_projectManager: [],
      ZHB_member: [],
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  created() {
    this.getOrgObj()

    this.$nextTick(() => {
      /** 点击 "日历 上个月" 按钮 **/
      let prevBtn = document.querySelector(
        '.el-calendar__button-group .el-button-group>button:nth-child(1)'
      )
      prevBtn.addEventListener('click', e => {
        /** 加载 "项目工时" 列表数据 **/
        this.getProjectHourListData(this.dateVal.format('yyyy-MM'))
      })

      /** 点击 "日历 下个月" 按钮 **/
      let nextBtn = document.querySelector(
        '.el-calendar__button-group .el-button-group>button:nth-child(3)'
      )
      nextBtn.addEventListener('click', e => {
        /** 加载 "项目工时" 列表数据 **/
        this.getProjectHourListData(this.dateVal.format('yyyy-MM'))
      })

      /** 点击 "日历 今天" 按钮 **/
      let todayBtn = document.querySelector(
        '.el-calendar__button-group .el-button-group>button:nth-child(2)'
      )
      todayBtn.addEventListener('click', e => {
        /** 加载 "项目工时" 列表数据 **/
        this.getProjectHourListData(this.dateVal.format('yyyy-MM'))
      })
    })
  },
  mounted() {
    this.getInit()
    // this.getZHBList()
  },
  methods: {
    getInit() {
      this.loading = true

      this.getProjectHourListData(this.dateVal.format('yyyy-MM')) /** 加载 "项目工时" 列表数据 **/
      this.getPartakeProjectData() /** 加载 "参与项目" 列表数据 **/
    },

    // 获取综合部成员信息
    // getZHBList() {
    //   this.ZHB_member = []
    //   this.ZHB_projectManager = []
    //   this.$api.user.listZhbMemberNotLeader().then(res => {
    //     res.data.forEach(item => {
    //       this.ZHB_member.push(item)
    //     })
    //   })
    //   this.$api.user.listZhbGroupLeader().then(res => {
    //     res.data.forEach(item => {
    //       this.ZHB_member.push(item)
    //       this.ZHB_projectManager.push(item)
    //     })
    //   })
    // },

    /**
     * 加载 "项目工时" 列表数据
     * @param date     年份-月份
     */
    getProjectHourListData(date) {
      this.$api.hour
        .getManhourByMonth(date)
        .then(res => {
          let obj = {}
          res.data.some(r => {
            let key = new Date(r.manhourDate.replace(/-/g, '/')).format('yyyy-MM-dd')
            if (!obj[key]) {
              obj[key] = {
                date: '',
                total: 0,
                manhourType: '',
                data: [],
              }
            }
            obj[key].date = key
            obj[key].total += Number(r.hour)
            obj[key].manhourType = r.manhourType
            obj[key].data.push(r)
          })

          let _pamse = []
          for (let key in obj) {
            if (obj[key].total < 3) {
              _pamse.push({
                ...obj[key],
                bg: '#E8523F',
              })
            } else if (obj[key].total < 6 && obj[key].total >= 3) {
              _pamse.push({
                ...obj[key],
                bg: '#FBB319',
              })
            } else {
              _pamse.push({
                ...obj[key],
                bg: '#014D67',
              })
            }
          }

          this.workingHoursData = _pamse
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 加载 "参与项目" 列表数据 **/
    getPartakeProjectData() {
      this.$api.hour
        .listProjectToManhour()
        .then(res => {
          this.projectData = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    //  获取YYYY-MM-DD格式的方法
    getDate(date) {
      let year = date.getFullYear()
      let month = (date.getMonth() + 1).toString().padStart(2, '0')
      let day = date.getDate().toString().padStart(2, '0')
      var time = year + '-' + month + '-' + day
      return time
    },
    /**
     * 点击 "日历" 日期
     * @param item     点击获取的数据
     */
    handleCalendar(item) {
      //判断是否退回工时,需要跳转重新提交
      let i = 0
      this.workingHoursData.forEach(e => {
        if (e.date.indexOf(item.day) != -1) {
          if (this.panduan(e.data, 3)) {
            i = 1
          }
        }
      })
      if (i == 1) {
        this.$message('当天工时被退回，请重新提交')
        this.$router.push({ path: '/hour/hourUser', query: { isfill: 1 } })
        return
      }

      var TimeDays = 1000 * 60 * 60 * 24 //一天
      let now = new Date(this.getDate(new Date())).getTime() //当前日期
      let click_time = Date.parse(new Date(item.day))
      this.itemData.itemData = 0
      if (click_time > now) {
        this.$message('请勿补录未来工时')
        return
      }

      if (now - click_time == 0) {
        this.$message('请勿补录当天工时，请前往工时填报板块填报')
        return
      }

      if (now - click_time == TimeDays && new Date().getHours() < 12) {
        this.$message('昨日工时未超出可填报时间，请前往工时填报板块填报')
        return
      }

      this.strDate = item.day
      this.isEditDlg = true

      /** 加载 "项目工时" 列表数据 **/
      this.getProjectHourListData(new Date(item.day).format('yyyy-MM'))
    },

    /** 点击 "统计" 图标 **/
    handleStatistics() {
      this.isProjectStatisticsDlg = true
    },

    /**
     * 点击 “参与项目” 列表
     * @param projectName     项目名称
     */
    handleProject(projectName) {
      let _param = {
        condition: {
          projectName: projectName,
        },
        pageNow: 1,
        pageSize: 1,
      }
      this.$api.projectManage
        .findPage(_param)
        .then(res => {
          this.form = res.data.records[0]
          this.editShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**
     * 调用 “工时填报 -- 弹出框” 子组件
     * @param  data  子组件 传过来的值
     */
    callEventEdit(data) {
      this.getInit()
      this.isEditDlg = data
    },

    /** 关闭 "子级" 组件 **/
    closeSubgroup() {
      this.isProjectStatisticsDlg = false
      this.editShow = false
    },
    /** 判断是否有暂存,未通过状态 */
    panduan(data, status = 0) {
      let i = 0

      if (status == 2) {
        i = 1
        data.forEach(e => {
          if (e.checkStatus != 2) {
            //有未通过的
            i = 0
          }
        })
      } else {
        data.forEach(e => {
          if (e.checkStatus == status) {
            i = 1
          }
        })
      }

      return i
    },
    getOrgObj() {
      this.$api.hour
        .organizationManhourType()
        .then(res => {
          this.orgObject = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getObjectKey(value) {
      const findResult = Object.keys(this.orgObject).find(key => this.orgObject[key] == value)
      if (findResult) {
        return findResult
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hour_bottom {
  height: 100%;
  padding: 8px 0;
  box-sizing: border-box;

  .hour_table_div {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding-top: 12px;
    border: 1px solid #d1d1d1;
    margin-right: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px #d1d1d1;
    box-sizing: border-box;

    .hour_tips {
      position: absolute;
      left: 50%;
      top: 25px;
      font-size: 12px;
      transform: translate(-50%);

      p {
        span:nth-child(2) {
          margin: 0 15px 0 5px;
        }
      }
    }

    /deep/.el-calendar {
      width: 100%;
      height: 100%;
      border-radius: 10px;

      .el-calendar__body {
        height: calc(100% - 53px);
      }

      .el-calendar-table {
        height: 100%;
      }

      .el-calendar-day {
        height: 100%;
      }
    }

    .div-Calendar {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;

      .total_hour {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #ffffff;
        width: 25px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        font-size: 16px;
      }
    }
  }

  .hour_fillin_div {
    width: 400px;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    box-shadow: 2px 2px 4px #d1d1d1;
    box-sizing: border-box;

    .fillin_top_div {
      height: 58px;
      padding: 0 20px;
      border-bottom: 1px solid #edf0f5;
      box-sizing: border-box;

      span {
        font-size: 22px;
        line-height: 58px;
        color: #333333;
      }

      .fillin_btn {
        margin-top: 15px;
      }

      .statistics_btn {
        float: right;
        color: #666666;
        margin-top: 3px;

        /deep/ .el-icon-s-data {
          font-size: 27px;
        }
      }
    }

    .fillin_bottom_div {
      padding: 0 20px 15px 20px;
      box-sizing: border-box;
      height: calc(100% - 58px);

      .scrollbar_div {
        width: 354px;
        height: calc(100vh - 285px);

        /deep/ .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        .no_data_p {
          font-size: 16px;
          text-align: center;
          padding-top: 30px;
        }

        ul {
          background-color: #f6f9fd;
          margin-top: 16px;
          padding: 22px 22px 15px 22px;
          border-radius: 10px;
          box-sizing: border-box;
          cursor: pointer;

          .name {
            color: #383d4a;
          }

          .time {
            color: #4d4d4d;
            margin-bottom: 5px;

            span:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.oh {
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.teal_bg {
  background-color: #014d67;
  border: 1px solid #014d67;
}

.zan_tips {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 3px;
  color: #fff;
  border-radius: 0% 0% 0% 40%;
  text-align: center;
  font-size: 14px;
}
.hour_bottom .hour_table_div .div-Calendar {
  font-size: 20px;
}

.bgc_6 {
  background: #666666;
}
.bgc_g {
  background: green;
}

.bgc_f {
  background: #f00;
}

.bl_text_tip {
  color: #f00;
  padding-left: 50px;
}

//取消日历选中背景颜色
.el-calendar-table td.is-selected {
  background-color: inherit;
}
/* 日历单元格鼠标经过背景色 */
.el-calendar-table .el-calendar-day:hover {
  background-color: #606688 !important;
}
</style>
